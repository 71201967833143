
import {Component, OnInit,  AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {OnPremAuthenticationService} from '../../core/services/onprem-authentication.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UtilsService} from '../../shared/services/utils.service';
import { AdalService } from '../../core/services/adal.service';
import {AuthService} from "../../core/services/auth.service";
import {CONFIGURATIONS} from "../../../config/configurations";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  @ViewChild('closeModel')
  private closeModel!: ElementRef;

  CLIENT_ID = 'pacman2_api_client';
  username: string = '';
  password: string = '';
  throwError: boolean = false;
  showErrorMessage = 'hide';
  loginPending: boolean | undefined;
  subscriptionToAssetGroup: Subscription | any;
  showOnPremLogin: boolean = false;
  // variable - default false
  show: boolean = false;

  // click event function toggle
  showPassword() {
    this.show = !this.show;
  }

  constructor(
    private authenticationService: OnPremAuthenticationService,
    private authService: AuthService,
    private router: Router,
    private utilityService: UtilsService,
    private adalService: AdalService,
    private onPremAuthentication: OnPremAuthenticationService,
    private el: ElementRef
  ) {
    if (CONFIGURATIONS.optional.auth.AUTH_TYPE === 'azuresso') {
      this.adalService.login();
    } else {
      this.showOnPremLogin = true;
    }

  }

  ngOnInit(): void {}

  login(data: NgForm) {
    if (!data.value.username || !data.value.password) {
      this.throwLoginError();
    } else {
      this.loginPending = true;
      const payload = {
        clientId: this.CLIENT_ID,
        username: this.authenticationService.formatUsernameWithoutDomain(
          data.value.username
        ),
        password: data.value.password,
      };

      this.authService
        .authenticateUserOnPrem(
          environment.login.url,
          environment.login.method,
          payload,
          {}
        )
        .subscribe(
          (result) => {
            this.loginPending = false;
            if (result.success) {
              data.reset();
              // this.el.nativeElement.classList.remove('modal-backdrop')
              this.closeModel.nativeElement.click()
              this.takeActionPostLogin(result);
            } else {
              this.throwLoginError();
            }
          },
          (error) => {
            this.loginPending = false;
            this.throwLoginError();
          }
        );
    }
  }

  closeSidemenu(data: NgForm) {
    // this.router.navigate(['/home']);
    data.reset();
    this.throwError = false;
 }

 outsideClick(event: Event , data: NgForm) {
  if((event.target as HTMLInputElement).id === 'modalForm') {
    // this.router.navigate(['/home']);
    data.reset();
    this.throwError = false;
  } 
 }

  throwLoginError() {
    this.showErrorMessage = 'show';
    this.throwError = true;

  }

  takeActionPostLogin(fetchedResult: any) {
    // Save user Details in cache
    this.onPremAuthentication.massageAndStoreUserDetails(fetchedResult);
    // Post saving user details to cache. Redirect user to dashboard.
    let defaultAssetGroup = null;
    if (fetchedResult && fetchedResult.userInfo) {
      defaultAssetGroup = fetchedResult.userInfo.defaultAssetGroup;
    }
    this.authService.redirectPostLogin(defaultAssetGroup);
  }
  showicon() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}

