<section>
  <div class="container-fluid py-1">
    <div class="row px-1 overall-main-div">
      <div class="col-md-6">
        <h6>Overall Vulnerabilities Compliance <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#cancel-btn"><i class="bi bi-question-circle text-dark"></i>
          </a></h6>
      </div>
      <div
        class="col-md-6 overall-vulnerabilities"
        *ngIf="vulnData">
        <div class="row">
          <div class="col-md-6 col-sm-6  text-md-end">
            <b class="text-green">{{vulnData.compliantpercent}}%</b>
            Compliant
          </div>
          <div class="col-md-6 col-sm-6 text-end">
            <b class="text-red">{{vulnData.vulnerabilities | number}}</b>
            Vulnerabilities (Across {{vulnData.hosts | number}} Hosts)
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-3 pe-lg-1 mb-2">  
        <div *ngIf="!contValue" class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      
        <div class="error_handling">
          <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
        </div>
        <div class="card pichartbox p-3" *ngIf='!seekdata'>
          <div class="row">
            <div class="firstbox">
              <div class="vulnerabilities-box pe-0">
                <div class="violet-badge"></div>
                <span> Inscope <b> {{modifiedResponse.total.data[0]}} ({{ (modifiedResponse.total.data[0]/modifiedResponse.total.totalCount)  | percent }})</b></span>
                <br>
                <div class="geeen2-badge"></div>
                <span> Exempted  <b> {{modifiedResponse.total.data[1]}} ({{ (modifiedResponse.total.data[1]/modifiedResponse.total.totalCount)  | percent }})</b></span>
              </div>
              <div class="secondbox">
                <div
                  id="graph1"
                  class="donut-chart ms-0"
                  data-amounts="500,100"
                  data-fills="#881E87,#01B27C"
                  data-size="130"
                  data-thickness="10">
                  <div class="chart-label invisible">0</div>
                </div>
              </div>             
            </div>
            <div class="chartbox">
              <app-circle-chart
              key="#graph1"
              value="{{(modifiedResponse.total.data[0]/modifiedResponse.total.totalCount)*100}},{{(modifiedResponse.total.data[1]/modifiedResponse.total.totalCount)*100}}"></app-circle-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 center-box mb-2">
        <div *ngIf="!contValue" class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      
        <div class="error_handling">
          <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
        </div>
        <div class="card pichartbox p-3" *ngIf='!seekdata'>
          <div class="row">
            <div class="firstbox">
            <div class="vulnerabilities-box pe-0">
              <div class="violet-badge"></div>
              <span> Scanned  <b>{{modifiedResponse.inscope.data[0]}} ({{ (modifiedResponse.inscope.data[0]/modifiedResponse.inscope.totalCount)  | percent }})</b></span>
              <br>
              <div class="geeen2-badge"></div>
              <span> Unscanned  <b>{{modifiedResponse.inscope.data[1]}} ({{ (modifiedResponse.inscope.data[1]/modifiedResponse.inscope.totalCount)  | percent }})</b></span>
              <br>
            </div>
            <div class="secondbox">
              <div
                id="graph6"
                class="donut-chart ms-0"
                data-amounts="20,80"
                data-fills="#881E87,#01B27C"
                data-size="130"
                data-thickness="10">
                <div class="chart-label invisible">0</div>
              </div>
            </div>
            <div class="chartbox">
              <app-circle-chart
                key="#graph6"
                value="{{(modifiedResponse.inscope.data[0]/modifiedResponse.inscope.totalCount)*100}},{{(modifiedResponse.inscope.data[1]/modifiedResponse.inscope.totalCount)*100}}"></app-circle-chart>
            </div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 center-box px-lg-1 pe-1 mb-2">
        <div *ngIf="!contValue" class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      
        <div class="error_handling">
          <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
        </div>
        <div class="card pichartbox p-3" *ngIf='!seekdata'>
          <div class="row">
            <div class="firstbox">
            <div class="vulnerabilities-box pe-0 pt-3">
              <div class="violet-badge"></div>
              <span> Noncompliant <b>{{modifiedResponse.scanned.data[0]}} ({{ (modifiedResponse.scanned.data[0]/modifiedResponse.scanned.totalCount)  | percent }})</b></span>
              <br>
              <div class="geeen2-badge pb-0"></div>
              <span> Compliant <b>{{modifiedResponse.scanned.data[1]}} ({{ (modifiedResponse.scanned.data[1]/modifiedResponse.scanned.totalCount)  | percent }})</b></span>
            </div>
            <div class="secondbox">
              <div
                id="graph3"
                class="donut-chart ms-0"
                data-amounts="20,80"
                data-fills="#881E87,#01B27C"
                data-size="130"
                data-thickness="10">
                <div class="chart-label invisible">0</div>
              </div>
            </div>
            </div>
            <div class="chartbox">
            <app-circle-chart
              key="#graph3"
              value="{{(modifiedResponse.scanned.data[0]/modifiedResponse.scanned.totalCount)*100}},{{(modifiedResponse.scanned.data[1]/modifiedResponse.scanned.totalCount)*100}}"></app-circle-chart>
            </div>
            </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 mb-2">
        <div *ngIf="!contValue" class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      
        <div class="error_handling">
          <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
        </div>
        <div class="card  p-4 compliant-card-div" *ngIf='!seekdata'>
          <div class="row">
            <div class="firstbox">
            <div
              class=" vulnerabilities-box pe-0 pt-4"
              *ngIf="modifiedResponse.noncompliant.totalCount>0">
              <div class="violet-badge"></div>
              <span> S3 - <b>{{modifiedResponse.noncompliant.data[0]}} ({{ (modifiedResponse.noncompliant.data[0]/modifiedResponse.noncompliant.totalCount)  | percent }})</b></span>
              <br>
              <div class="geeen2-badge"></div>
              <span> S4 - <b> {{modifiedResponse.compliant.data[1]}} ({{ (modifiedResponse.compliant.data[1]/modifiedResponse.compliant.totalCount)  | percent }})</b></span>
              <br>
              <div class="orange-badge"></div>
              <span> S5 -  <b>{{modifiedResponse.compliant.data[2]}} ({{ (modifiedResponse.compliant.data[2]/modifiedResponse.compliant.totalCount)  | percent }})</b></span>
              <br>
            </div>
            <div
              class="vulnerabilities-box pe-0 pt-4"
              *ngIf="modifiedResponse.noncompliant.totalCount==0">
              <div class="violet-badge"></div>
              <span> S3 - <b>0 ({{ 0 | percent }})</b></span>
              <br>
              <div class="geeen2-badge"></div>
              <span> S4 -  <b>0 ({{ 0 | percent }})</b></span>
              <br>
              <div class="orange-badge"></div>
              <span> S5 -  <b>0 ({{ 0 | percent }})</b></span>
              <br>
            </div>
            <div class="secondbox">
              <div
                id="graph4"
                class="donut-chart ms-0"
                data-amounts="20,20,60"
                data-fills="#881E87,#01B27C,#FC6A59"
                data-size="130"
                data-thickness="10">
                <div class="chart-label invisible">0</div>
              </div>
            </div>
            </div>
            <div class="chartbox">
            <app-circle-chart
              *ngIf="modifiedResponse.noncompliant.totalCount>0"
              key="#graph4"
              value="{{(modifiedResponse.compliant.data[0]/modifiedResponse.compliant.totalCount)*100}},{{(modifiedResponse.compliant.data[1]/modifiedResponse.compliant.totalCount)*100}},{{(modifiedResponse.compliant.data[2]/modifiedResponse.compliant.totalCount)*100}}"></app-circle-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
