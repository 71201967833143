import { Component, OnInit,ViewChild } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { NgxSelectComponent } from 'ngx-select-ex';
import { environment } from 'src/environments/environment';
import { GrcService } from 'src/app/pacman-features/services/grc.service';
import { MaturityGraphComponent } from './maturity-graph/maturity-graph.component';
import { SecurityPiechartComponent } from './security-piechart/security-piechart.component';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-security-posture-assessment',
  templateUrl: './security-posture-assessment.component.html',
  styleUrls: ['./security-posture-assessment.component.css']
})
export class SecurityPostureAssessmentComponent implements OnInit {
  breadcrumbArray: any = ['GRC'];
  breadcrumbLinks: any = ['Security-Posture-Assessment'];
  breadcrumbPresent: any = 'Security-Posture-Assessment';
  pageTitle: string = 'Security-Posture-Assessment';
  @ViewChild('targetType') targetTypeSelectComponent: NgxSelectComponent | any;
  @ViewChild('targetTypeRuleSelect') targetTypeRuleSelectComponent: NgxSelectComponent | any;
  @ViewChild(MaturityGraphComponent) maturitygraph!: MaturityGraphComponent; // Access child component instance
  @ViewChild(SecurityPiechartComponent) securitygraph!: SecurityPiechartComponent; // Access child component instance
  defaultValue:any=[0]
  ismscategory: any = [];
  ismscategoryname: any=[];
  errorMessage: any;
  selectedPersonId: number | any;
  selectedDomain:any;
  contValue = false;
  loaded = false;
  public selectControl = new FormControl();
  constructor(private breadCurmbService: BreadcrumbService,private grcService:GrcService,) {this.getAllcategory(); }

  ngOnInit() {
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    setTimeout(() => {
      this.selectControl.setValue(["All"]);
      this.ismscategoryname=["All"];
    },1000)
    
  }
  onSelectcategory(onSelectcategory:any){
    this.selectedDomain=onSelectcategory;
    this.maturitygraph.getAllMaturitylevel(this.selectedDomain);
    this.securitygraph.getByOwner(this.selectedDomain);
  }
  getAllcategory() {    
    const url = environment.categoryall.url;
    const method = environment.categoryall.method;    
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      this.contValue = true;
          this.loaded = true;
      this.ismscategory.push(
        {
          id: 'All',
          name: 'all',
        });
      for (const item of reponse[0]) {
        this.ismscategory.push(
          {
            id: item.domainName,
            name: item.id,
          });          
      }
      
    },
      (error: any) => {
        this.ismscategory = [];
        this.errorMessage = 'apiResponseError';
     
      });
  }
  
}
