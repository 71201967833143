<!--Header Section-->
    <section>
        <!--Top Navigation Section Start-->
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
          <div class="container">
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link" aria-current="page" (click)="goTo('highlights')">Highlights</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="goTo('features')">Features</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" >Contact Us</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link green-btn"  data-bs-toggle="modal" (click)="login()" data-bs-target="#modalForm">Sign In <i class="bi bi-arrow-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <!--Top Navigation Section Ends-->
    </section>
<!--Header Section end-->



    <!-- Header Banner Start-->
    <section>
        <div class="container-fluid">
          <div class="row">           
            <div class="img-gradient px-0" style="background-image:url(/assets/images/banner.jpg);">

              <div class="banner-txt">
                <h1>UST <span> Cloud Manager</span></h1>               
                <h2>Compliance Monitoring module</h2>
                <p>A platform for continuous compliance monitoring, compliance reporting and security automation for Cloud.</p>
                <a class="btn banner-button" (click)="login()" data-bs-toggle="modal" data-bs-target="#modalForm">Lets Get Started <i class="bi bi-arrow-right"></i></a>
              </div>
           
            </div>

          </div>
        </div>
     </section>
    <!-- Header Banner Ends-->


    <!-- what we do start -->
    <section>
      <div class="whatwedo bg-light">
          <div class="container">
            <h3>WHAT WE DO</h3>
            <div class="row">
              <div class="col-md-4 ">
                   <div class="blue-box text-center text-white p-4"><h6>Assess</h6>
                    <img class="d-block mx-auto my-3" src="/assets/images/icon-assess.svg" width="48px">
                    <p>UST MCM continuously assess the state of the cloud and changes in real-time to assess our cloud security and governance policy compliance.</p>
                   </div>  
              </div>
              <div class="col-md-4">
                <div class="green-box text-center text-white  p-4"><h6>Report</h6>
                  <img class="d-block mx-auto my-3" src="/assets/images/icon-report.svg" width="45px">
                  <p>UST MCM provides an easy way to view, monitor and report on the security and compliance of the entire cloud eco-system.</p>

                </div>  
              </div>
              <div class="col-md-4 ">
                <div class="purple-box text-center text-white  p-4"><h6>Remediate</h6>
                  <img class="d-block mx-auto my-3" src="/assets/images/icon-remediate.svg" width="31px">
                  <p>With detailed contextual information about the compliance violation, remediation is quick with UST MCM. It provides auto-fixes and one click fix options.</p>

                </div>  
              </div>
            </div>
          </div>

      </div>
    </section>     
    <!-- what we do end -->

    <!-- Highlights start -->
    <section >
      <div  id="highlights" class="highlights">
      <div class="container">  
      <h3>HIGHLIGHTS</h3>
      <div class="row">

        <div class="col-md-4 col-6">
          <div class="greybox p-4">
            <img class="my-3" src="/assets/images/icon-continuous-monitoring.svg" width="44px">
            <h5>Continuous<br>Monitoring</h5>
            <p>Real-time & continuous monitoring to detect changes as they happen.</p>
          </div>
        </div>

        <div class="col-md-4 col-6">
          <div class="greybox p-4">
            <img class="my-3" src="/assets/images/icon-reporting.svg" width="43px">
            <h5>Compliance<br>Reporting</h5>
            <p>Easily produce compliance reports with latest results from continuous compliance monitoring.</p>
          </div> 
        </div>

        <div class="col-md-4 col-6">
          <div class="greybox p-4">
            <img class="my-3" src="/assets/images/icon-security-wide.svg" width="62px">
            <h5>Security That <br>Goes Wide</h5>
            <p>See what’s happening across all your applications, environments, AWS accounts, regions and services.</p>
          </div>
        </div>

        <div class="col-md-4 col-6">
          <div class="greybox p-4">
            <img class="my-3" src="/assets/images/icon-security-deep.svg" width="22px">
            <h5>Security That <br>Goes Deep</h5>
            <p>Check your environment against hundreds of customizable security best practices.</p>
          </div>
        </div>

        <div class="col-md-4 col-6">
          <div class="greybox p-4">
            <img class="my-3" src="/assets/images/icon-technical-support.svg" width="45px">
            <h5>Security Automation</h5>
            <p>Continuous visibility, automated data collection, clear visualization and alerting accelerates incident response and mitigates further risks.</p>
          </div>
        </div>

      </div>
     </div>
      </div>
    </section>
    <!-- Highlights end -->

    <!-- Get Started start -->
    <section>
      <div id="features" class="getstarted">
          <div class="container">
            <div class="row text-center">
              <div class="col-md-6 left-text"><p>Ready to easily monitor your compliance and assets?</p></div> 
              <div class="col-md-6 right-text"> <a class="btn signin me-2" (click)="login()" data-bs-toggle="modal" data-bs-target="#modalForm">Sign In <i class="bi bi-arrow-right"></i></a> <span class="text-white">TO GET STARTED </span></div>
             
            </div>

           </div>
       </div>
      </section>
    <!-- Get Started End-->

    <!-- Key Components start -->
    <section>
        <div  class="container">
          <div class="keycomponets">
          <h2>5 KEY COMPONENTS OF UST MCM</h2>
          <div class="row">
            <div class="col-md-4 my-4 p-r">
              <h4>Rule Engine</h4>
              <p>Real-time & continuous monitoring to detect changes as they happen.</p>
            </div>
            <div class="col-md-4 my-4 p-r">
              <h4>UI</h4>
              <p>Easily produce compliance reports with latest results from continuous compliance monitoring.</p>
            </div>
            <div class="col-md-4 my-4 p-r">
              <h4>Search Engine</h4>
              <p>See what’s happening across all your applications, environments, AWS accounts, regions and services.</p>
            </div>
            <div class="col-md-4 my-4 p-r">
              <h4>Scheduled Data Collector - AWS Batch</h4>
              <p>Check your environment against hundreds of customizable security best practices.</p>
            </div>
            <div class="col-md-4 my-4 p-r">
              <h4>Data Lake</h4>
              <p>Continuous visibility, automated data collection, clear visualization and alerting accelerates incident response and mitigates further risks.</p>
            </div>
          </div>
         </div>
        </div>
    </section>
    <app-login></app-login>

<!-- <router-outlet ></router-outlet> -->