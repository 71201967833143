<section class="security">
  <div class="container-fluid main-heading">
    <div class="row">
      <div class="col-md-6 col-6 text-left mt-1">
        <h6>Security Posture Assessment </h6>
      </div>
      <div class="col-md-6 col-6 text-end mb-2 category">
        <div class="heading-gray1">ISMS Category</div>   
        <ngx-select class="form-select"
        [items]="ismscategory"
        [formControl]="selectControl"
        optionValueField = "id"
        optionTextField = "id"
        (select)="onSelectcategory($event)"
        [autoClearSearch]="false"
        placeholder="Select Category"
        required>
        </ngx-select>
      </div>
    </div>
  </div>
</section>
<div class="container-fluid mt-1">
  <div class="card">    
      <section>
        <app-security-piechart ></app-security-piechart>
      </section>    
  </div>
</div>
<div class="container-fluid mt-4">
  <div class="card">
    <div class="card-body">
      <section>
        <app-maturity-graph></app-maturity-graph>
      </section>
    </div>
  </div>
</div>
