<!-- ======= Sidebar ======= -->

<aside id="sidebar" class="sidebar" [ngClass]="addClass ? 'active-nav' : ''">
  <ul class="sidebar-nav" id="sidebar-nav">
    <li routerLinkActive #rla1="routerLinkActive" class="nav-item" *ngIf="config.required.featureModules.COMPLIANCE_MODULE">
      <a [ngClass]="rla1.isActive ? '' : 'collapsed'" class="nav-link" data-bs-target="#components-nav" data-bs-toggle="collapse">
        Compliance<i class="bi bi-chevron-right ms-auto"></i>
      </a>
      <ul [ngClass]="rla1.isActive ? 'show' : ''" id="components-nav" class="nav-content collapse" data-bs-parent="#sidebar-nav">
        <li *ngFor="let list of listOfContextualMenuItems" routerLink='./compliance/{{list.route}}' [queryParams]="agAndDomain">
          <a routerLinkActive="active"> {{list.name}}</a>
        </li>
      </ul>
    </li>

    <li routerLinkActive #rla="routerLinkActive" class="nav-item" *ngIf='config.required.featureModules.ASSETS_MODULE'>
      <a [ngClass]="rla.isActive ? '' : 'collapsed'" class="nav-link" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
        Assets<i class="bi bi-chevron-right ms-auto"></i>
      </a>
      <ul [ngClass]="rla.isActive ? 'show' : ''" id="forms-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li *ngFor="let list1 of assetListDomainList" routerLink='./assets/{{list1.route}}' [queryParams]="agAndDomain">
          <a routerLinkActive="active"> {{list1.name}}</a>
        </li>
      </ul>
    </li>

    <li routerLinkActive #rla="routerLinkActive" class="nav-item" *ngIf='config.required.featureModules.ADMIN_MODULE && adminAccess'>
      <a [ngClass]="rla.isActive ? '' : 'collapsed'" class="nav-link" data-bs-target="#forms-nav-admin" data-bs-toggle="collapse" href="#">
        Admin<i class="bi bi-chevron-right ms-auto"></i>
      </a>
      <ul [ngClass]="rla.isActive ? 'show' : ''" id="forms-nav-admin" class="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li *ngFor="let list1 of adminListDomainList" routerLink='./admin/{{list1.route}}' [queryParams]="agAndDomain">
          <a routerLinkActive="active"> {{list1.name}}</a>
        </li>
      </ul>
    </li>
    <li routerLinkActive #rla="routerLinkActive" class="nav-item" *ngIf='config.required.featureModules.GRC_MODULE'>
      <a [ngClass]="rla.isActive ? '' : 'collapsed'" class="nav-link" data-bs-target="#forms-nav-grc" data-bs-toggle="collapse" href="#">
        GRC<i class="bi bi-chevron-right ms-auto"></i>
      </a>
      <ul [ngClass]="rla.isActive ? 'show' : ''" id="forms-nav-grc" class="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li routerLink='./grc/riskoverview'>
          <a routerLinkActive="active">Overview</a>
        </li>
        <li routerLink='./grc/risk-management'>
          <a routerLinkActive="active">Risk Management</a>
        </li>
        <li routerLink='./grc/security-assessment'>
          <a routerLinkActive="active">Security-Posture</a>
        </li>
      </ul>
    </li>
  </ul><!-- End Components Nav -->
</aside><!-- End Sidebar-->
