
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../core/services/auth.service';
import {LoggerService} from './logger.service';
import {DataCacheService} from '../../core/services/data-cache.service';
import {CONFIGURATIONS} from '../../../config/configurations';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private loggerService: LoggerService,
    private dataStore: DataCacheService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    /* Full url is taken, as redirect function will remove the hostname and just add the pathname */
    const returnUrl = location.href;
    if (!this.authService.authenticated) {
      this.loggerService.log('info', 'AuthGuard - Authentication required to access this page');
      // Store the redirect url in cache
      this.dataStore.setRedirectUrl(returnUrl);

      // Redirect to login
      this.authService.doLogin();
      return false;
    }
    this.loggerService.log('info', 'AuthGuard - User authenticated, user is granted access for this page');
    const checkUserRoles = this.dataStore.getUserDetailsValue().getRoles();
    if (checkUserRoles.length <= 0) {
      this.authService.setUserFetchedInformation().subscribe((response: any) => {
          this.loggerService.log('info', '**Successfully set user Fetched information**');
        },
        (error: any) => {
          this.loggerService.log('info', '**Error in setting user Fetched information**');
        });
    }

    // TODO: Need confirmation on Qualys
    // if (state.url.includes('vulnerabilities-compliance') && !CONFIGURATIONS.optional.general.qualysEnabled) {
    //   this.loggerService.log('info', 'Qualys required to access this page');
    //   this.router.navigate(
    //     ['../../../../compliance/compliance-dashboard'],
    //     {relativeTo: this.activatedRoute, queryParamsHandling: 'merge'}
    //   );
    //   return false;
    // }
    return true;
  }
}
